let wasm;
export function __wbg_set_wasm(val) {
    wasm = val;
}


const heap = new Array(128).fill(undefined);

heap.push(undefined, null, true, false);

function getObject(idx) { return heap[idx]; }

let heap_next = heap.length;

function dropObject(idx) {
    if (idx < 132) return;
    heap[idx] = heap_next;
    heap_next = idx;
}

function takeObject(idx) {
    const ret = getObject(idx);
    dropObject(idx);
    return ret;
}

const lTextDecoder = typeof TextDecoder === 'undefined' ? (0, module.require)('util').TextDecoder : TextDecoder;

let cachedTextDecoder = new lTextDecoder('utf-8', { ignoreBOM: true, fatal: true });

cachedTextDecoder.decode();

let cachedUint8ArrayMemory0 = null;

function getUint8ArrayMemory0() {
    if (cachedUint8ArrayMemory0 === null || cachedUint8ArrayMemory0.byteLength === 0) {
        cachedUint8ArrayMemory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachedUint8ArrayMemory0;
}

function getStringFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return cachedTextDecoder.decode(getUint8ArrayMemory0().subarray(ptr, ptr + len));
}

let cachedDataViewMemory0 = null;

function getDataViewMemory0() {
    if (cachedDataViewMemory0 === null || cachedDataViewMemory0.buffer.detached === true || (cachedDataViewMemory0.buffer.detached === undefined && cachedDataViewMemory0.buffer !== wasm.memory.buffer)) {
        cachedDataViewMemory0 = new DataView(wasm.memory.buffer);
    }
    return cachedDataViewMemory0;
}

let cachedInt16ArrayMemory0 = null;

function getInt16ArrayMemory0() {
    if (cachedInt16ArrayMemory0 === null || cachedInt16ArrayMemory0.byteLength === 0) {
        cachedInt16ArrayMemory0 = new Int16Array(wasm.memory.buffer);
    }
    return cachedInt16ArrayMemory0;
}

function getArrayI16FromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return getInt16ArrayMemory0().subarray(ptr / 2, ptr / 2 + len);
}
/**
* @param {CardSet} set
* @returns {Int16Array}
*/
export function card_codes(set) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm.card_codes(retptr, set);
        var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
        var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
        var v1 = getArrayI16FromWasm0(r0, r1).slice();
        wasm.__wbindgen_free(r0, r1 * 2, 2);
        return v1;
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

/**
* @param {CardSet} set
* @param {number} code
* @returns {number | undefined}
*/
export function card_index(set, code) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm.card_index(retptr, set, code);
        var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
        var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
        return r0 === 0 ? undefined : r1 >>> 0;
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

/**
* @param {CardSet} set
* @param {number} index
* @returns {string | undefined}
*/
export function card_name(set, index) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm.card_name(retptr, set, index);
        var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
        var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
        let v1;
        if (r0 !== 0) {
            v1 = getStringFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 1, 1);
        }
        return v1;
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

/**
* @param {CardSet} set
* @param {number} index
* @returns {Kind | undefined}
*/
export function card_type(set, index) {
    const ret = wasm.card_type(set, index);
    return ret === 6 ? undefined : ret;
}

/**
* @param {CardSet} set
* @param {number} index
* @returns {number | undefined}
*/
export function card_element(set, index) {
    const ret = wasm.card_element(set, index);
    return ret === 0xFFFFFF ? undefined : ret;
}

/**
* @param {CardSet} set
* @param {number} index
* @returns {number | undefined}
*/
export function card_rarity(set, index) {
    const ret = wasm.card_rarity(set, index);
    return ret === 0xFFFFFF ? undefined : ret;
}

/**
* @param {CardSet} set
* @param {number} index
* @returns {number | undefined}
*/
export function card_cost(set, index) {
    const ret = wasm.card_cost(set, index);
    return ret === 0xFFFFFF ? undefined : ret;
}

/**
* @param {CardSet} set
* @param {number} index
* @returns {number | undefined}
*/
export function card_costele(set, index) {
    const ret = wasm.card_costele(set, index);
    return ret === 0xFFFFFF ? undefined : ret;
}

/**
* @param {CardSet} set
* @param {number} index
* @returns {boolean}
*/
export function card_pillar(set, index) {
    const ret = wasm.card_pillar(set, index);
    return ret !== 0;
}

/**
* @param {CardSet} set
* @param {number} index
* @returns {boolean}
*/
export function card_token(set, index) {
    const ret = wasm.card_token(set, index);
    return ret !== 0;
}

/**
* @param {CardSet} set
* @param {number} x
* @param {number} y
* @returns {number}
*/
export function code_cmp(set, x, y) {
    const ret = wasm.code_cmp(set, x, y);
    return ret;
}

/**
* @param {number} seed
* @returns {number}
*/
export function original_oracle(seed) {
    const ret = wasm.original_oracle(seed);
    return ret;
}

/**
* @param {CardSet} set
* @param {number} col
* @param {number} element
* @param {number} rarity
* @returns {Int16Array}
*/
export function selector_filter(set, col, element, rarity) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm.selector_filter(retptr, set, col, element, rarity);
        var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
        var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
        var v1 = getArrayI16FromWasm0(r0, r1).slice();
        wasm.__wbindgen_free(r0, r1 * 2, 2);
        return v1;
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

/**
* @param {number} e1
* @param {number} e2
* @param {number} uprate
* @param {number} markpower
* @param {number} maxrarity
* @param {number} seed
* @returns {Int16Array}
*/
export function deckgen_duo(e1, e2, uprate, markpower, maxrarity, seed) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm.deckgen_duo(retptr, e1, e2, uprate, markpower, maxrarity, seed);
        var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
        var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
        var v1 = getArrayI16FromWasm0(r0, r1).slice();
        wasm.__wbindgen_free(r0, r1 * 2, 2);
        return v1;
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

/**
* @param {number} uprate
* @param {number} markpower
* @param {number} maxrarity
* @param {number} seed
* @returns {Int16Array}
*/
export function deckgen_bow(uprate, markpower, maxrarity, seed) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm.deckgen_bow(retptr, uprate, markpower, maxrarity, seed);
        var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
        var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
        var v1 = getArrayI16FromWasm0(r0, r1).slice();
        wasm.__wbindgen_free(r0, r1 * 2, 2);
        return v1;
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

/**
* @param {number} e1
* @param {number} e2
* @param {number} seed
* @returns {Int16Array}
*/
export function deckgen_ai4(e1, e2, seed) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm.deckgen_ai4(retptr, e1, e2, seed);
        var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
        var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
        var v1 = getArrayI16FromWasm0(r0, r1).slice();
        wasm.__wbindgen_free(r0, r1 * 2, 2);
        return v1;
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

let cachedUint16ArrayMemory0 = null;

function getUint16ArrayMemory0() {
    if (cachedUint16ArrayMemory0 === null || cachedUint16ArrayMemory0.byteLength === 0) {
        cachedUint16ArrayMemory0 = new Uint16Array(wasm.memory.buffer);
    }
    return cachedUint16ArrayMemory0;
}

let WASM_VECTOR_LEN = 0;

function passArray16ToWasm0(arg, malloc) {
    const ptr = malloc(arg.length * 2, 2) >>> 0;
    getUint16ArrayMemory0().set(arg, ptr / 2);
    WASM_VECTOR_LEN = arg.length;
    return ptr;
}

function isLikeNone(x) {
    return x === undefined || x === null;
}
/**
* @param {CardSet} set
* @param {number} idx
* @returns {string}
*/
export function card_text(set, idx) {
    let deferred1_0;
    let deferred1_1;
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm.card_text(retptr, set, idx);
        var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
        var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
        deferred1_0 = r0;
        deferred1_1 = r1;
        return getStringFromWasm0(r0, r1);
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
        wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
    }
}

function addHeapObject(obj) {
    if (heap_next === heap.length) heap.push(heap.length + 1);
    const idx = heap_next;
    heap_next = heap[idx];

    heap[idx] = obj;
    return idx;
}

const lTextEncoder = typeof TextEncoder === 'undefined' ? (0, module.require)('util').TextEncoder : TextEncoder;

let cachedTextEncoder = new lTextEncoder('utf-8');

const encodeString = (typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
    return cachedTextEncoder.encodeInto(arg, view);
}
    : function (arg, view) {
    const buf = cachedTextEncoder.encode(arg);
    view.set(buf);
    return {
        read: arg.length,
        written: buf.length
    };
});

function passStringToWasm0(arg, malloc, realloc) {

    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length, 1) >>> 0;
        getUint8ArrayMemory0().subarray(ptr, ptr + buf.length).set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len, 1) >>> 0;

    const mem = getUint8ArrayMemory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7F) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, len = offset + arg.length * 3, 1) >>> 0;
        const view = getUint8ArrayMemory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
        ptr = realloc(ptr, len, offset, 1) >>> 0;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}
/**
*/
export const Phase = Object.freeze({ Mulligan:0,"0":"Mulligan",Play:1,"1":"Play",End:2,"2":"End", });
/**
*/
export const Stat = Object.freeze({ adrenaline:0,"0":"adrenaline",atk:1,"1":"atk",card:2,"2":"card",cast:3,"3":"cast",castele:4,"4":"castele",casts:5,"5":"casts",charges:6,"6":"charges",cost:7,"7":"cost",costele:8,"8":"costele",delayed:9,"9":"delayed",dive:10,"10":"dive",flooding:11,"11":"flooding",frozen:12,"12":"frozen",gpull:13,"13":"gpull",hp:14,"14":"hp",lives:15,"15":"lives",maxhp:16,"16":"maxhp",mode:17,"17":"mode",nova:18,"18":"nova",nova2:19,"19":"nova2",poison:20,"20":"poison",shardgolem:21,"21":"shardgolem",sosa:22,"22":"sosa",steam:23,"23":"steam",storedpower:24,"24":"storedpower",swarmhp:25,"25":"swarmhp", });
/**
*/
export const CardSet = Object.freeze({ Open:0,"0":"Open",Original:1,"1":"Original", });
/**
*/
export const Kind = Object.freeze({ Weapon:0,"0":"Weapon",Shield:1,"1":"Shield",Permanent:2,"2":"Permanent",Spell:3,"3":"Spell",Creature:4,"4":"Creature",Player:5,"5":"Player", });
/**
*/
export const Sfx = Object.freeze({ creaPlay:0,"0":"creaPlay",devour:1,"1":"devour",dive:2,"2":"dive",freeze:3,"3":"freeze",lobo:4,"4":"lobo",mulligan:5,"5":"mulligan",permPlay:6,"6":"permPlay",poison:7,"7":"poison",skelify:8,"8":"skelify",stasis:9,"9":"stasis", });

const GameFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_game_free(ptr >>> 0, 1));
/**
*/
export class Game {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Game.prototype);
        obj.__wbg_ptr = ptr;
        GameFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        GameFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_game_free(ptr, 0);
    }
    /**
    * @returns {number}
    */
    get turn() {
        const ret = wasm.__wbg_get_game_turn(this.__wbg_ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set turn(arg0) {
        wasm.__wbg_set_game_turn(this.__wbg_ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get winner() {
        const ret = wasm.__wbg_get_game_winner(this.__wbg_ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set winner(arg0) {
        wasm.__wbg_set_game_winner(this.__wbg_ptr, arg0);
    }
    /**
    * @returns {Phase}
    */
    get phase() {
        const ret = wasm.__wbg_get_game_phase(this.__wbg_ptr);
        return ret;
    }
    /**
    * @param {Phase} arg0
    */
    set phase(arg0) {
        wasm.__wbg_set_game_phase(this.__wbg_ptr, arg0);
    }
    /**
    * @param {number} seed
    * @param {CardSet} set
    * @param {number} players
    * @param {number} now
    */
    constructor(seed, set, players, now) {
        const ret = wasm.game_new(seed, set, players, now);
        this.__wbg_ptr = ret >>> 0;
        GameFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
    /**
    * @param {number} id
    * @param {number} k
    * @returns {number}
    */
    get_stat(id, k) {
        const ret = wasm.game_get_stat(this.__wbg_ptr, id, k);
        return ret;
    }
    /**
    * @param {number} id
    * @returns {number}
    */
    get_mark(id) {
        const ret = wasm.game_get_mark(this.__wbg_ptr, id);
        return ret;
    }
    /**
    * @param {number} id
    * @returns {number}
    */
    get_drawpower(id) {
        const ret = wasm.game_get_drawpower(this.__wbg_ptr, id);
        return ret;
    }
    /**
    * @param {number} id
    * @returns {number}
    */
    get_deckpower(id) {
        const ret = wasm.game_get_deckpower(this.__wbg_ptr, id);
        return ret;
    }
    /**
    * @param {number} id
    * @returns {number}
    */
    get_markpower(id) {
        const ret = wasm.game_get_markpower(this.__wbg_ptr, id);
        return ret;
    }
    /**
    * @param {number} id
    * @returns {number}
    */
    get_owner(id) {
        const ret = wasm.game_get_owner(this.__wbg_ptr, id);
        return ret;
    }
    /**
    * @param {number} id
    * @returns {Kind}
    */
    get_kind(id) {
        const ret = wasm.game_get_kind(this.__wbg_ptr, id);
        return ret;
    }
    /**
    * @param {number} id
    * @returns {number}
    */
    get_foe(id) {
        const ret = wasm.game_get_foe(this.__wbg_ptr, id);
        return ret;
    }
    /**
    * @param {number} id
    * @returns {number}
    */
    get_weapon(id) {
        const ret = wasm.game_get_weapon(this.__wbg_ptr, id);
        return ret;
    }
    /**
    * @param {number} id
    * @returns {number}
    */
    get_shield(id) {
        const ret = wasm.game_get_shield(this.__wbg_ptr, id);
        return ret;
    }
    /**
    * @returns {Game}
    */
    clonegame() {
        const ret = wasm.game_clonegame(this.__wbg_ptr);
        return Game.__wrap(ret);
    }
    /**
    * @param {number} id
    * @returns {boolean}
    */
    full_hand(id) {
        const ret = wasm.game_full_hand(this.__wbg_ptr, id);
        return ret !== 0;
    }
    /**
    * @param {number} id
    * @returns {boolean}
    */
    empty_hand(id) {
        const ret = wasm.game_empty_hand(this.__wbg_ptr, id);
        return ret !== 0;
    }
    /**
    * @param {number} id
    * @returns {boolean}
    */
    has_id(id) {
        const ret = wasm.game_has_id(this.__wbg_ptr, id);
        return ret !== 0;
    }
    /**
    * @param {number} id
    * @returns {Int16Array}
    */
    get_hand(id) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.game_get_hand(retptr, this.__wbg_ptr, id);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var v1 = getArrayI16FromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 2, 2);
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {number} id
    * @returns {number}
    */
    deck_length(id) {
        const ret = wasm.game_deck_length(this.__wbg_ptr, id);
        return ret >>> 0;
    }
    /**
    * @param {number} id
    * @param {number} ele
    * @returns {number}
    */
    get_quanta(id, ele) {
        const ret = wasm.game_get_quanta(this.__wbg_ptr, id, ele);
        return ret;
    }
    /**
    * @param {number} id
    * @returns {number}
    */
    count_creatures(id) {
        const ret = wasm.game_count_creatures(this.__wbg_ptr, id);
        return ret;
    }
    /**
    * @param {number} id
    * @returns {number}
    */
    count_permanents(id) {
        const ret = wasm.game_count_permanents(this.__wbg_ptr, id);
        return ret;
    }
    /**
    * @param {number} id
    * @param {number} p1id
    * @returns {number}
    */
    hand_overlay(id, p1id) {
        const ret = wasm.game_hand_overlay(this.__wbg_ptr, id, p1id);
        return ret;
    }
    /**
    * @param {number} id
    * @param {number} p1id
    * @param {number} p2id
    * @param {number} expected
    * @returns {string}
    */
    hp_text(id, p1id, p2id, expected) {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.game_hp_text(retptr, this.__wbg_ptr, id, p1id, p2id, expected);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @returns {number}
    */
    hash() {
        const ret = wasm.game_hash(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} id
    * @param {number} leader
    */
    set_leader(id, leader) {
        wasm.game_set_leader(this.__wbg_ptr, id, leader);
    }
    /**
    * @param {number} id
    * @returns {number}
    */
    get_leader(id) {
        const ret = wasm.game_get_leader(this.__wbg_ptr, id);
        return ret;
    }
    /**
    * @param {number} id
    * @param {number} hp
    * @param {number} maxhp
    * @param {number} mark
    * @param {number} drawpower
    * @param {number} deckpower
    * @param {number} markpower
    * @param {Int16Array} deck
    */
    init_player(id, hp, maxhp, mark, drawpower, deckpower, markpower, deck) {
        const ptr0 = passArray16ToWasm0(deck, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.game_init_player(this.__wbg_ptr, id, hp, maxhp, mark, drawpower, deckpower, markpower, ptr0, len0);
    }
    /**
    * @param {number} id
    * @returns {string | undefined}
    */
    get_cast_skill(id) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.game_get_cast_skill(retptr, this.__wbg_ptr, id);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {number} c
    * @param {number} t
    * @returns {string | undefined}
    */
    actinfo(c, t) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.game_actinfo(retptr, this.__wbg_ptr, c, t);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {number} id
    * @returns {string}
    */
    instance_text(id) {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.game_instance_text(retptr, this.__wbg_ptr, id);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {number} id
    * @returns {string}
    */
    thingText(id) {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.game_thingText(retptr, this.__wbg_ptr, id);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {number} x
    * @param {number} c
    * @param {number} t
    * @param {boolean} fx
    * @returns {Int16Array | undefined}
    */
    next(x, c, t, fx) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.game_next(retptr, this.__wbg_ptr, x, c, t, fx);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            let v1;
            if (r0 !== 0) {
                v1 = getArrayI16FromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 2, 2);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {number} id
    * @returns {number}
    */
    getIndex(id) {
        const ret = wasm.game_getIndex(this.__wbg_ptr, id);
        return ret;
    }
    /**
    * @param {number} id
    * @param {Kind | undefined} [kind]
    * @returns {boolean}
    */
    material(id, kind) {
        const ret = wasm.game_material(this.__wbg_ptr, id, isLikeNone(kind) ? 6 : kind);
        return ret !== 0;
    }
    /**
    * @param {number} p1id
    * @param {number} p2id
    * @returns {Int16Array}
    */
    visible_instances(p1id, p2id) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.game_visible_instances(retptr, this.__wbg_ptr, p1id, p2id);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var v1 = getArrayI16FromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 2, 2);
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {number} id
    * @returns {number}
    */
    visible_status(id) {
        const ret = wasm.game_visible_status(this.__wbg_ptr, id);
        return ret >>> 0;
    }
    /**
    * @returns {boolean}
    */
    has_flooding() {
        const ret = wasm.game_has_flooding(this.__wbg_ptr);
        return ret !== 0;
    }
    /**
    * @param {number} id
    * @returns {boolean}
    */
    is_cloaked(id) {
        const ret = wasm.game_is_cloaked(this.__wbg_ptr, id);
        return ret !== 0;
    }
    /**
    * @param {number} id
    * @returns {boolean}
    */
    requires_target(id) {
        const ret = wasm.game_requires_target(this.__wbg_ptr, id);
        return ret !== 0;
    }
    /**
    * @param {number} c
    * @param {number} t
    * @returns {boolean}
    */
    can_target(c, t) {
        const ret = wasm.game_can_target(this.__wbg_ptr, c, t);
        return ret !== 0;
    }
    /**
    * @returns {Int16Array}
    */
    aisearch() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.game_aisearch(retptr, this.__wbg_ptr);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var v1 = getArrayI16FromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 2, 2);
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {number}
    */
    aieval() {
        const ret = wasm.game_aieval(this.__wbg_ptr);
        return ret;
    }
    /**
    * @param {number} id
    * @returns {boolean}
    */
    canactive(id) {
        const ret = wasm.game_canactive(this.__wbg_ptr, id);
        return ret !== 0;
    }
    /**
    * @param {number} id
    * @param {number} qtype
    * @param {number} amt
    * @returns {boolean}
    */
    canspend(id, qtype, amt) {
        const ret = wasm.game_canspend(this.__wbg_ptr, id, qtype, amt);
        return ret !== 0;
    }
    /**
    * @param {number} samples
    * @returns {Int16Array}
    */
    expected_damage(samples) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.game_expected_damage(retptr, this.__wbg_ptr, samples);
            var r0 = getDataViewMemory0().getInt32(retptr + 4 * 0, true);
            var r1 = getDataViewMemory0().getInt32(retptr + 4 * 1, true);
            var v1 = getArrayI16FromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 2, 2);
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    */
    tracedeath() {
        wasm.game_tracedeath(this.__wbg_ptr);
    }
    /**
    * @param {number} id
    * @param {number} p1id
    * @param {boolean} landscape
    * @returns {number}
    */
    tgt_to_pos(id, p1id, landscape) {
        const ret = wasm.game_tgt_to_pos(this.__wbg_ptr, id, p1id, landscape);
        return ret >>> 0;
    }
}

export function __wbindgen_object_drop_ref(arg0) {
    takeObject(arg0);
};

export function __wbg_new_abda76e883ba8a5f() {
    const ret = new Error();
    return addHeapObject(ret);
};

export function __wbg_stack_658279fe44541cf6(arg0, arg1) {
    const ret = getObject(arg1).stack;
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
};

export function __wbg_error_f851667af71bcfc6(arg0, arg1) {
    let deferred0_0;
    let deferred0_1;
    try {
        deferred0_0 = arg0;
        deferred0_1 = arg1;
        console.error(getStringFromWasm0(arg0, arg1));
    } finally {
        wasm.__wbindgen_free(deferred0_0, deferred0_1, 1);
    }
};

export function __wbindgen_throw(arg0, arg1) {
    throw new Error(getStringFromWasm0(arg0, arg1));
};

